import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"

import Nav from "./nav-services"
// import Masthead from "./masthead"
import Products from "./products"
import ProductsCarousel from "./products-carousel"
import ContactUs from "./contact-us"
import Footer from "./footer"

if (typeof window !== 'undefined') {
  require('smooth-scroll')('a[href*="#"]');
}


const HomeTechnologyContent = ({ siteTitle }) => (
  <div>
    <Nav/>
    <div className="masthead-bg services">
      <div className="container m-auto">
        <div className="w-full pl-4 pt-14 pb-8 lg:w-3/5 md:w-1/2
        md:pt-32 lg:pt-20 xl:pt-32
        md:mt-4  lg:mt-8  xl:mt-8  2xl:mt-16 
        md:pb-24 lg:pb-64 xl:pb-80 2xl:pb-96">
          <div className="services-masthead-label">
            QOI HOME TECHNOLOGY
          </div>
          <div className="masthead-headline animate__animated animate__fadeInLeft">
          Making your <br/>
smart home smarter <br/>
and simpler
          </div>
        </div>
      </div>
    
    </div>
    <div className="flex">
      <div className="services-left w-full lg:w-2/5 place-content-center flex flex-col">
        <div className="">
          <div className="services-title">
            WHOLE HOME INTEGRATION
          </div>
          <div className="services-tagline">
          A smart home for every lifestyle
          </div>
          <div className="p-0 m-0 leading-0 lg:w-0 lg:hidden">
            <StaticImage src="../images/whole-home-integration@2x.jpg" className=" w-full" alt="Whole Home Integration"/>
          </div>

          <div className="service-body">
          One app can let you control your entire home with simplicity. Watch movies, adjust your lights, control your temperature, and manage your security from anywhere in your house, or anywhere in the world.
          </div>
        </div>
      </div>

      <div className="w-0 lg:w-3/5">
        <div className="p-0 m-0 leading-0">
          <StaticImage src="../images/whole-home-integration@2x.jpg" className=" w-full" alt="Whole Home Integration"/>
        </div>
      </div>

    </div>

    <div className="flex">

      <div className="w-0 lg:w-3/5">
        <div className="p-0 m-0 leading-0">
          <StaticImage src="../images/custom-home-theatre@2x.jpg" className=" w-full" alt="Contact Us"/>
        </div>
      </div>

      <div className="services-right w-full lg:w-2/5">
        <div className="">
          <div className="services-title">
          CUSTOM HOME THEATER
          </div>
          <div className="services-tagline">
          Unparalleled theater experience
          </div>
          <div className="p-0 m-0 leading-0 lg:w-0 lg:hidden">
          <StaticImage src="../images/custom-home-theatre@2x.jpg" className=" w-full" alt="Custom Home Theatre"/>
          </div>

          <div className="services-body">
          Want to enter a new world? Or watch the big game up close? Our home controller for your screen, receivers, and speakers would give you full access over your system, and your movies from a simple interface turning everyday into something magical.
          </div>
        </div>
      </div>

    </div>    



    <div className="flex">
      <div className="wd-full lg:w-2/5 services-left">
        <div className="">
          <div className="services-title">
          NETWORKING
          </div>
          <div className="services-tagline">
          The central system of your connected home
          </div>
          
          <div className="p-0 m-0 leading-0 lg:w-0 lg:hidden">
            <StaticImage src="../images/networking@2x.jpg" className=" w-full" alt="Networking"/>
          </div>

          <div className="services-body">
          With so many devices taxing your home network, are you sure it’s still working efficiently? We built a strong home network to support every smart home. Start any journey right with a reliable network.
          </div>
        </div>
      </div>

      <div className="w-0 lg:w-3/5">
        <div className="p-0 m-0 leading-0">
          <StaticImage src="../images/networking@2x.jpg" className=" w-full" alt="Networking"/>
        </div>
      </div>

    </div>


    <div className="flex">

      <div className="w-0 lg:w-3/5">
        <div className="p-0 m-0 leading-0">
          <StaticImage src="../images/multi-room-audio-video@2x.jpg" className=" w-full" alt="Multi Room Audio/Video"/>
        </div>
      </div>

      <div className="w-full lg:w-2/5 services-right">
        <div className="">
          <div className="services-title">
          MULTI-ROOM AUDIO/VIDEO
          </div>
          <div className="services-tagline">
          Simple, clean, and
clutter free
          </div>

          <div className="p-0 m-0 leading-0 lg:w-0 lg:hidden">
            <StaticImage src="../images/multi-room-audio-video@2x.jpg" className=" w-full" alt="Multi Room Audio/Video"/>
          </div>


          <div className="services-body">
          Play your audio/video in just one room or throughout your whole house. That’s limitless entertainment possibilities with just the touch of a single remote.
          </div>
        </div>
      </div>

    </div>   



    <div className="flex">
      <div className="w-full lg:w-2/5 services-left">
        <div className="">
          <div className="services-title">
          SMART LIGHTING
          </div>
          <div className="services-tagline">
          Elegant and efficient lighting is always a bright idea
          </div>
          <div className="p-0 m-0 leading-0 lg:w-0 lg:hidden">
            <StaticImage src="../images/smart-lighting@2x.jpg" className=" w-full" alt="Smart Lighting"/>
          </div>
          <div className="services-body">
          Going around your house to turn your lights on or off can be a real hassle. Smart lighting makes your home both beautiful, and energy efficient. Your lights automatically turn on when you need it, and off when no one is in the room.
          </div>
        </div>
      </div>

      <div className="w-0 lg:w-3/5">
        <div className="p-0 m-0 leading-0">
          <StaticImage src="../images/smart-lighting@2x.jpg" className=" w-full" alt="Smart Lighting"/>
        </div>
      </div>

    </div>

    <Products/>
    <ProductsCarousel/>
    
    <div className="text-center py-8">
      <div className="text-3xl font-saira">
      We Value What You Value
      </div>
      <div className="">
        <div className="w-8 border-t-8 my-6 border-accent inline-block">

        </div>
        <div className="mb-8">
        We’ve got everything you’ll need to make the most out of your smart home.
        </div>
      </div>


    </div>

    <div className="md:flex">
      <div className="w-full md:w-1/2">
        <div className="p-0 m-0 leading-0 opacity-50 hover:opacity-100">
          <a href="/home-security">

          <StaticImage src="../images/btn-home-security.png" className="w-full" alt="Home Security"/>
          </a>
        </div>
      </div>
      <div className="w-full md:w-1/2">
        <div className="p-0 m-0 leading-0 opacity-50 hover:opacity-100">
          <a href="https://www.qoismartshades.com/" target="_blank">
          <StaticImage src="../images/btn-smart-shades.png" className="w-full" alt="Home Security"/></a>
        </div>
      </div>
    </div>
    <ContactUs/>
    <Footer/>
  </div>
)

export default HomeTechnologyContent