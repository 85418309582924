import * as React from "react"

import HomeTechnologyContent from "../components/home-technology-content"
import Seo from "../components/seo"

const HomeTechnologyPage = () => (
  <div>
    <Seo title="Home Technology" />
    <HomeTechnologyContent/>
  </div>
)

export default HomeTechnologyPage
